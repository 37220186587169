var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kt-widget kt-widget--user-profile-2" },
    [
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "kt-widget__footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-label-warning btn-lg btn-upper",
            attrs: { type: "button" },
            on: { click: _vm.openModal },
          },
          [_vm._v(" Download ")]
        ),
      ]),
      _c("CourseAttendanceForDateModal", { ref: "attendance-modal" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget__head" }, [
      _c("div", { staticClass: "kt-widget__media" }, [
        _c("img", {
          staticStyle: { width: "50px" },
          attrs: { src: "/images/files/csv.svg", alt: "image" },
        }),
      ]),
      _c("div", { staticClass: "kt-widget__info" }, [
        _c(
          "a",
          { staticClass: "kt-widget__titel kt-hidden-", attrs: { href: "#" } },
          [_vm._v("Course Attendance")]
        ),
        _c("span", { staticClass: "kt-widget__desc" }, [_vm._v("Records")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget__body" }, [
      _c("div", { staticClass: "kt-widget__section" }, [
        _vm._v(
          " Downloads course attendance records for date range, including missing values. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }