var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row w-100 px-3 pt-1 pb-2" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "col-sm-6 col-md-4 col-lg-4 col-xl-3" },
        [
          _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
            _vm._m(0, true),
            _c(
              "div",
              { staticClass: "kt-portlet__body" },
              [_c(item.component, { tag: "component" })],
              1
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "kt-portlet__head kt-portlet__head--noborder" },
      [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          _c("h3", { staticClass: "kt-portlet__head-title" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }