<template>
<div class="row w-100 px-3 pt-1 pb-2">
    <div
        v-for="(item, index) in items"
        :key="index"
        class="col-sm-6 col-md-4 col-lg-4 col-xl-3"
    >
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title" />
                </div>
            </div>
            <div class="kt-portlet__body">
                <component :is="item.component" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import AttendanceReveralsDetails from '../../components/FileDownloaders/AttendanceReversals/AttendanceReveralsDetails.vue';
import AttendancePercentagesDetails from '../../components/FileDownloaders/AttendancePercentages/AttendancePercentagesDetails.vue';
import CourseAttendanceForDate from '../../components/FileDownloaders/CourseAttendanceForDate/CourseAttendanceForDate.vue';
import StudentLinking from '../../components/FileDownloaders/StudentLinking/StudentLinking.vue';
import TeacherLinking from '../../components/FileDownloaders/TeacherLinking/TeacherLinking.vue';
import StudentProgress from '../../components/FileDownloaders/StudentProgress/StudentProgress.vue';
import AssignmentsProgress from '../../components/FileDownloaders/AssignmentsProgress/AssignmentsProgress.vue';
import HomeUserLogInStatus from '../../components/FileDownloaders/HomeUserLogInStatus.vue';

export default {
    name: 'CSVDownloader',
    components: {
        AttendanceReveralsDetails,
        AttendancePercentagesDetails,
        CourseAttendanceForDate,
        StudentLinking,
        StudentProgress,
        TeacherLinking,
        AssignmentsProgress,
        HomeUserLogInStatus,
    },
    data() {
        return {
            items: [
                // { component: 'AttendanceReveralsDetails' },
                // { component: 'AttendancePercentagesDetails' },
                { component: 'CourseAttendanceForDate' },
                { component: 'StudentLinking' },
                { component: 'StudentProgress' },
                { component: 'TeacherLinking' },
                { component: 'AssignmentsProgress' },
                { component: 'HomeUserLogInStatus' },
            ],
        };
    },
};

</script>
