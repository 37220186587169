var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-widget kt-widget--user-profile-2" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "kt-widget__footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-label-warning btn-lg btn-upper",
          class: {
            "kt-spinner kt-spinner--sm kt-spinner--dark": _vm.generating,
          },
          attrs: { type: "button" },
          on: { click: _vm.download },
        },
        [_vm._v(" Download ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget__head" }, [
      _c("div", { staticClass: "kt-widget__media" }, [
        _c("img", {
          staticStyle: { width: "50px" },
          attrs: { src: "/images/files/csv.svg", alt: "image" },
        }),
      ]),
      _c("div", { staticClass: "kt-widget__info" }, [
        _c(
          "a",
          { staticClass: "kt-widget__titel kt-hidden-", attrs: { href: "#" } },
          [_vm._v("Home User Status")]
        ),
        _c("span", { staticClass: "kt-widget__desc" }, [
          _vm._v("Home User Log In Status"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget__body" }, [
      _c("div", { staticClass: "kt-widget__section" }, [
        _vm._v(
          " Downloads a CSV of student and guardian log in statuses for the current term. Only includes guardians with access to SyncGrades Home. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }