<template>
<div class="kt-widget kt-widget--user-profile-2">
    <div class="kt-widget__head">
        <div class="kt-widget__media">
            <img
                style="width: 50px"
                src="/images/files/csv.svg"
                alt="image"
            >
        </div>
        <div class="kt-widget__info">
            <a
                href="#"
                class="kt-widget__titel kt-hidden-"
            >Home User Status</a>
            <span class="kt-widget__desc">Home User Log In Status</span>
        </div>
    </div>
    <div class="kt-widget__body">
        <div class="kt-widget__section">
            Downloads a CSV of student and guardian log in statuses for the current term.
            Only includes guardians with access to SyncGrades Home.
        </div>
    </div>
    <div class="kt-widget__footer">
        <button
            type="button"
            class="btn btn-label-warning btn-lg btn-upper"
            :class="{'kt-spinner kt-spinner--sm kt-spinner--dark': generating}"
            @click="download"
        >
            Download
        </button>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import * as network from '../../network';

export default {
    name: 'HomeUserLogInStatus',
    data() {
        return {
            generating: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    methods: {
        download() {
            const v = this;
            if (v.generating) return;

            v.generating = true;
            const { user, showError } = v;
            const { schoolId, schoolTermId, externalSchoolId } = user.school;
            network.guardians.getLogInStatusForTerm({ url: { schoolId, schoolTermId } }, (err, res) => {
                v.generating = false;
                if (err) return showError(err);

                const csvColumns = [
                    { key: 'lastName', title: 'Last Name' },
                    { key: 'firstName', title: 'First Name' },
                    { key: 'role', title: 'Role' },
                    { key: 'studentLastName', title: 'Student Last Name' },
                    { key: 'studentFirstName', title: 'Student First Name' },
                    { key: 'extStudentId', title: 'Student OSIS' },
                    { key: 'gradeLevel', title: 'Grade Level' },
                    { key: 'homeRoom', title: 'Home Room' },
                    { key: 'relationToStudent', title: 'Relation To Student' },
                    { key: 'guardianAuthorization', title: 'Guardian Authorization' },
                    { key: 'lastLogIn', title: 'Last Log In' },
                ];

                const data = res.homeUserLoginStatus;
                const labelsData = csvColumns.map((column) => column.key);
                let csv = csvColumns
                    .map((header) => header.title)
                    .join(',');
                csv += '\n';
                data.forEach((row) => {
                    csv += labelsData
                        .map((cell) => `"${row[cell] || ''}"`)
                        .join(',');
                    csv += '\n';
                });

                const date = moment(new Date()).format('YYYYMMDD');
                const filename = `${externalSchoolId}_HomeUser_LogIn_Status_${date}`.replaceAll(' ', '_');
                const anchor = document.createElement('a');
                anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
                anchor.target = '_blank';
                anchor.download = `${filename}.csv`;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            });
        },
    },
};
</script>
