import CourseAttendanceForDateModal from './CourseAttendanceForDateModal.vue';

export default {
    name: 'CourseAttendanceForDate',
    components: {
        CourseAttendanceForDateModal,
    },
    data() {
        return {};
    },
    methods: {
        openModal() {
            this.$refs['attendance-modal'].open();
        },
    },
};
