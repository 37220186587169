<template>
<div
    v-if="user.school.schoolId > 0"
    class="kt-container kt-grid__item kt-grid__item--fluid w-100 pl-0 pr-0"
>
    <div
        v-if="user.school.role != 'School Admin'"
        role="alert"
        class="alert alert-solid-brand alert-bold"
    >
        <div class="alert-icon">
            <i class="flaticon-danger" />
        </div>
        <div
            class="alert-text"
            style="line-height: 40px"
        >
            You do not have permission to access this page
        </div>
    </div>
    <div v-else>
        <div class="row">
            <CSVDownloader />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import CSVDownloader from './CSVDownloader.vue';

export default {
    name: 'FileDownloader',
    components: {
        CSVDownloader,
    },
    data() {
        return {};
    },
    computed: mapState({
        user: (state) => state.user,
    }),
    methods: {},
};
</script>

<style></style>
