import AttendanceReveralsModal from './AttendanceReveralsModal.vue';

export default {
    name: 'AttendanceReveralsDetails',
    components: {
        AttendanceReveralsModal,
    },
    data() {
        return {};
    },
    methods: {
        openModal() {
            this.$refs['attendance-reversals'].open();
        },
    },
};
