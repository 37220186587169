import { mapState } from 'vuex';

const network = require('../../../lib/network');

export default {
    name: 'StudentProgress',
    data() {
        return {};
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    methods: {
        download() {
            const v = this;
            network.download.studentProgressSummary(v.user, (err, res) => {
                if (err) return alert(err);

                const blob = new Blob([res], { type: 'application/vnd.ms-excel' });

                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `${v.user.school.externalSchoolId}_student_progress_summary.csv`;
                document.body.appendChild(a);
                a.click();
            });
        },
    },
};
