import moment from 'moment';
import { mapState } from 'vuex';
import network from '../../../lib/network';

export default {
    name: 'AttendanceReveralsModal',
    components: {
    },
    data() {
        return {
            saving: false,
            date: moment().format('YYYY-MM-DD'),
            includeCreated: false,
        };
    },
    computed: mapState({
        user: (state) => state.user,
        dateFormatted() {
            return moment(this.date, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
        startDateFormatted() {
            return moment(this.theStartDate, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
        endDateFormatted() {
            return moment(this.theEndDate, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
        theStartDate() {
            const date = moment(this.date, 'YYYY-MM-DD').clone();
            if (date.format('ddd') == 'Mon') {
                return date.add(-2, 'days').format('YYYY-MM-DD');
            }
            return this.date;
        },
        theEndDate() {
            const date = moment(this.date, 'YYYY-MM-DD').clone();
            return date.add(1, 'days').format('YYYY-MM-DD');
        },
    }),
    methods: {
        dateDisabled(ymd, date) {
            const selected = moment(ymd, 'YYYY-MM-DD');
            const weekday = date.getDay();
            return weekday === 0 || weekday === 6 || moment() < selected;
        },
        download() {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            network.download.attendanceReversals(v.user, v.date, v.theStartDate, v.theEndDate, v.includeCreated, (err, res) => {
                v.saving = false;
                if (err) return alert(err);
                const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
                if (blob.size == 0) return alert('No data found');
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `${v.user.school.externalSchoolId}_reverals_${v.date}.xlsx`;
                document.body.appendChild(a);
                a.click();

                v.$refs['attendance-reversal-modal'].hide();
            });
        },
        open() {
            const v = this;
            v.$refs['attendance-reversal-modal'].show();
        },
    },
};
