var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-modal", {
    ref: "attendance-percentages-modal",
    attrs: { variant: "primary", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" " + _vm._s(_vm.name) + " "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-4 col-form-label",
                    attrs: { for: "example-text-input" },
                  },
                  [_vm._v("Start Date")]
                ),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "form-control kt-input",
                      attrs: { dropbottom: "" },
                      model: {
                        value: _vm.theStartDate,
                        callback: function ($$v) {
                          _vm.theStartDate = $$v
                        },
                        expression: "theStartDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-4 col-form-label",
                    attrs: { for: "example-text-input" },
                  },
                  [_vm._v("End Date")]
                ),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "form-control kt-input",
                      attrs: { dropbottom: "" },
                      model: {
                        value: _vm.theEndDate,
                        callback: function ($$v) {
                          _vm.theEndDate = $$v
                        },
                        expression: "theEndDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-4 col-form-label",
                    attrs: { for: "example-text-input" },
                  },
                  [_vm._v("Flag at cutoff %")]
                ),
                _c("div", { staticClass: "col-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cutoff,
                        expression: "cutoff",
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "99",
                      pattern: "^([0-9]|[1-9][0-9]|100)$",
                    },
                    domProps: { value: _vm.cutoff },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.cutoff = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-info pull-right",
                class: {
                  "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.download()
                  },
                },
              },
              [_vm._v(" Download ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }