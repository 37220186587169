
import AttendancePercentagesModal from "./AttendancePercentagesModal.vue";

export default {
    name: "AttendancePercentagesDetails",
    components: {
        AttendancePercentagesModal,
    },
    data() {
        return {}
    },
    methods: {
        openModal() {
            this.$refs['attendance-percentages'].open();
        }
    }
}