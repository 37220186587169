var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-modal", {
    ref: "attendance-reversal-modal",
    attrs: { variant: "primary", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Attendance Reversals "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-4 col-form-label",
                    attrs: { for: "example-text-input" },
                  },
                  [_vm._v("Date")]
                ),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "form-control kt-input",
                      attrs: {
                        "date-disabled-fn": _vm.dateDisabled,
                        dropbottom: "",
                      },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-lg-4 col-form-label" }, [
                  _vm._v("Submission State:"),
                ]),
                _c("div", { staticClass: "col-lg-8" }, [
                  _c("div", { staticClass: "kt-checkbox-inline" }, [
                    _c("label", { staticClass: "kt-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.includeCreated,
                            expression: "includeCreated",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.includeCreated)
                            ? _vm._i(_vm.includeCreated, null) > -1
                            : _vm.includeCreated,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.includeCreated,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.includeCreated = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.includeCreated = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.includeCreated = $$c
                            }
                          },
                        },
                      }),
                      _vm._v("Include Created "),
                      _c("span"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-secondary",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("div", { staticClass: "alert-icon" }, [
                      _c("i", { staticClass: "flaticon-info kt-font-brand" }),
                    ]),
                    _c("div", { staticClass: "alert-text" }, [
                      _vm._v(" Returns students who were marked "),
                      _c("code", [_vm._v("Absent")]),
                      _vm._v(" or "),
                      _c("code", [_vm._v("Online Absent")]),
                      _vm._v(" on "),
                      _c("code", [_vm._v(_vm._s(_vm.dateFormatted))]),
                      _vm._v(
                        " interaction, and also submitted or started on a google assignment from start of day on "
                      ),
                      _c("code", [_vm._v(_vm._s(_vm.startDateFormatted))]),
                      _vm._v(" until the beginning of the day on "),
                      _c("code", [_vm._v(_vm._s(_vm.endDateFormatted))]),
                      _vm._v(". "),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group row" }, [
                _c("span", { staticClass: "form-text text-muted" }, [
                  _vm._v(" A google submission state of "),
                  _c("code", [_vm._v("CREATED")]),
                  _vm._v(
                    " can sometimes return a false positive when received from google. It is recommened to exclude CREATED submission states when calculating interactions. "
                  ),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-info pull-right",
                class: {
                  "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.download()
                  },
                },
              },
              [_vm._v(" Download ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }