import AssignmentsProgressModal from './AssignmentsProgressModal.vue';

export default {
    name: 'AssignmentsProgress',
    components: {
        AssignmentsProgressModal,
    },
    data() {
        return { };
    },
    computed: { },
    methods: {
        openModal() {
            this.$refs['assignments-modal'].open();
        },
    },
};
