var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.school.schoolId > 0
    ? _c(
        "div",
        {
          staticClass:
            "kt-container kt-grid__item kt-grid__item--fluid w-100 pl-0 pr-0",
        },
        [
          _vm.user.school.role != "School Admin"
            ? _c(
                "div",
                {
                  staticClass: "alert alert-solid-brand alert-bold",
                  attrs: { role: "alert" },
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "alert-text",
                      staticStyle: { "line-height": "40px" },
                    },
                    [_vm._v(" You do not have permission to access this page ")]
                  ),
                ]
              )
            : _c("div", [
                _c("div", { staticClass: "row" }, [_c("CSVDownloader")], 1),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-danger" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }