import moment from 'moment';
import { mapState } from 'vuex';
import network from '../../../lib/network';

export default {
    name: 'AttendancePercentagesModal',
    components: {
    },
    data() {
        return {
            name: 'Attendance Percentages',
            saving: false,
            theStartDate: null,
            theEndDate: null,
            cutoff: 90,
        };
    },
    computed: mapState({
        user: (state) => state.user,
        startDateFormatted() {
            return moment(this.theStartDate, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
        endDateFormatted() {
            return moment(this.theEndDate, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
    }),
    mounted() {
        const v = this;
        this.theStartDate = moment(v.user.school.termStart).format('YYYY-MM-DD');
        this.theEndDate = moment(v.user.school.termEnd).format('YYYY-MM-DD');
    },
    methods: {
        download() {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            network.download.attendancePercentages(v.user, v.theStartDate, v.theEndDate, v.cutoff, (err, res) => {
                v.saving = false;
                if (err) return alert(err);
                const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
                if (blob.size == 0) return alert('No data found');
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `${v.user.school.externalSchoolId}_percentages_${v.theStartDate}-${v.theEndDate}.xlsx`;
                document.body.appendChild(a);
                a.click();

                v.$refs['attendance-percentages-modal'].hide();
            });
        },
        open() {
            const v = this;
            v.$refs['attendance-percentages-modal'].show();
        },
    },
};
