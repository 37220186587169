<template>
<b-modal
    ref="assignments-modal"
    variant="primary"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Assignments Progress
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <div class="modal-body">
            <div class="form-group row">
                <label
                    for="example-text-input"
                    class="col-4 col-form-label"
                >Start Date</label>
                <div class="col-8">
                    <b-form-datepicker
                        v-model="startDate"
                        dropbottom
                        class="form-control kt-input"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="example-text-input"
                    class="col-4 col-form-label"
                >End Date</label>
                <div class="col-8">
                    <b-form-datepicker
                        v-model="endDate"
                        dropbottom
                        class="form-control kt-input"
                    />
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-info pull-right"
            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
            :disabled="saving"
            @click="download()"
        >
            Download
        </button>
    </template>
</b-modal>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import network from '../../../lib/network';

export default {
    name: 'AssignmentsProgressModal',
    components: {
    },
    data() {
        return {
            saving: false,
            startDate: null,
            endDate: null,
        };
    },
    computed: mapState({
        user: (state) => state.user,
    }),
    mounted() {
        const v = this;
        v.startDate = moment().format('YYYY-MM-DD');
        v.endDate = moment().format('YYYY-MM-DD');
    },
    methods: {
        download() {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            network.download.assignmentsProgressForSchoolAdmins(v.user, v.startDate, v.endDate, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Unable to download assignments progress');

                const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
                if (blob.size == 0) return v.showError('No assignment data found');
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `${v.user.school.externalSchoolId}_assignments_${v.startDate}-${v.endDate}.xlsx`;
                document.body.appendChild(a);
                a.click();

                v.$refs['assignments-modal'].hide();
            });
        },
        open() {
            const v = this;
            v.$refs['assignments-modal'].show();
        },
    },
};
</script>
